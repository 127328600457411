import { Record, String as StringRT } from "runtypes";

import { config } from "../../../../config";
import { omitUndefined } from "../../../core/object";
import { HttpResult } from "../../../infra/HttpResult";
import {
  CreateMemberOrgResultDtoRT,
  CreateOrganizationResponse,
  CreateOrganizationResponseRT,
  InstantiateMemberOrgDocsResultDto,
  InstantiateMemberOrgDocsResultDtoRT,
  LicenseTypesResponse,
  LicenseTypesResponseRT,
  OrganizationDetailsV2Dto,
  OrganizationDetailsV2DtoRT,
  OrganizationsListV2Dto,
  OrganizationsListV2DtoRT,
} from "../../../infra/api_responses/admin/organizations";
import { authedRequest, decodedAuthedRequest } from "../shared";
import { CustomThemeSpec } from "../../stats/shared/core/colors/colorSchemes";

export function listLicenseTypes(): Promise<HttpResult<LicenseTypesResponse>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/licenses",
    null,
    "GET",
    LicenseTypesResponseRT
  );
}

export function listOrganizationsV2(): Promise<
  HttpResult<OrganizationsListV2Dto>
> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/organizations",
    null,
    "GET",
    OrganizationsListV2DtoRT
  );
}

export type NewLicensesSpec = {
  licenseTypeId: number;
  maxLicenseCount: number;
};

export function createOrganizationV2(
  name: string,
  emailDomains: string[],
  licenses: NewLicensesSpec[]
): Promise<HttpResult<CreateOrganizationResponse>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/organizations",
    {
      name,
      email_domains: emailDomains,
      licenses: licenses.map((l) => ({
        id: l.licenseTypeId,
        max_count: l.maxLicenseCount,
      })),
    },
    "POST",
    CreateOrganizationResponseRT
  );
}

export function deleteUserFromOrganization(
  orgId: string,
  userId: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/users/${userId}`,
    undefined,
    "DELETE"
  );
}

export function deleteOrganizationInvite(
  orgId: string,
  inviteId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/invites/${inviteId}`,
    undefined,
    "DELETE"
  );
}

export function deleteOrganizationLicenseV2(
  orgId: string,
  licenseId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/licenses/${licenseId}`,
    undefined,
    "DELETE"
  );
}

export function editOrganizationLicenseV2(
  orgId: string,
  license: { id: number; maxNumLicenses: number }
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/licenses`,
    {
      license_id: license.id,
      max_count: license.maxNumLicenses,
    },
    "POST"
  );
}

export function removeUserLicense(
  orgId: string,
  userId: string,
  licenseId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/users/${userId}/licenses/${licenseId}`,
    undefined,
    "DELETE"
  );
}

export function addUserLicense(
  orgId: string,
  userId: string,
  licenseId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/users/${userId}/licenses`,
    { license_id: licenseId },
    "POST"
  );
}

export function addUserRole(
  orgId: string,
  userId: string,
  roleId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/users/${userId}/roles`,
    { role_id: roleId },
    "POST"
  );
}

export function removeUserRole(
  orgId: string,
  userId: string,
  roleId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/users/${userId}/roles/${roleId}`,
    undefined,
    "DELETE"
  );
}

export function addOrganizationInvites(
  orgId: string,
  email: string,
  licenseIds: number[],
  roleIds: number[]
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/invites`,
    omitUndefined({
      email,
      licenses: licenseIds,
      roles: roleIds.length > 0 ? roleIds : undefined,
    }),
    "POST"
  );
}

export function sendOrganizationInviteEmailToUser(
  orgId: string,
  inviteId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/invites/${inviteId}/notifybyemail`,
    undefined,
    "POST"
  );
}

export function getOrganizationV2(
  id: string
): Promise<HttpResult<OrganizationDetailsV2Dto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/organizations/" + id,
    undefined,
    "GET",
    OrganizationDetailsV2DtoRT
  );
}

export function removeOrganizationBrandingV2(
  orgId: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/custombranding`,
    undefined,
    "DELETE"
  );
}

export function updateOrganizationV2(
  id: string,
  org: Partial<OrganizationDetailsV2Dto>
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "admin/organizations/" + id,
    { ...org, id },
    "PATCH"
  );
}

export function createThirdPartyOrg(
  parentOrgId: string,
  name: string,
  emailDomains: string[],
  adminEmail: string
) {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/organizations/" + parentOrgId + "/thirdpartyorgs",
    { admin_email: adminEmail, name, email_domains: emailDomains },
    "POST",
    CreateMemberOrgResultDtoRT
  );
}

export function removeThirdPartyOrg(
  parentOrgId: string,
  thirdPartyOrgId: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${parentOrgId}/thirdpartyorgs/${thirdPartyOrgId}`,
    undefined,
    "DELETE"
  );
}

export function addThirdPartyOrgMembers(
  parentOrgId: string,
  thirdPartyOrgId: string,
  emails: string[]
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${parentOrgId}/thirdpartyorgs/${thirdPartyOrgId}/addresses`,
    { addresses: emails },
    "POST"
  );
}

export function updateThirdPartyOrgMember(
  parentOrgId: string,
  thirdPartyOrgId: string,
  email: string,
  admin: boolean
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${parentOrgId}/thirdpartyorgs/${thirdPartyOrgId}/addresses`,
    { admin, address: email },
    "PATCH"
  );
}

export function removeThirdPartyOrgMember(
  parentOrgId: string,
  thirdPartyOrgId: string,
  email: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${parentOrgId}/thirdpartyorgs/${thirdPartyOrgId}/addresses`,
    { address: email },
    "DELETE"
  );
}

const CreateThirdPartyDocResultRT = Record({
  third_party_doc_id: StringRT,
});
export function createThirdPartyDoc(
  sourceDocId: number,
  senderOrgId: string,
  thirdPartyOrgIds: string[],
  sendEmailToRecipients: boolean,
  accessIds: { microIds: number[]; statsIds: number[] }
): Promise<HttpResult<{ third_party_doc_id: string }>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `admin/documents/thirdparty`,
    {
      source_document_id: sourceDocId,
      sender_organization_id: senderOrgId,
      third_party_org_ids: thirdPartyOrgIds,
      send_mail_to_recipients: sendEmailToRecipients,
      access_data_ids: accessIds.statsIds,
      access_mikro_ids: accessIds.microIds,
    },
    "POST",
    CreateThirdPartyDocResultRT
  );
}

export function createThirdPartyDocsFromTemplate(
  template: unknown,
  placeholderInfo: unknown,
  sourceDocumentId: number,
  accessStatsIds: number[],
  accessMicroIds: number[],
  sendEmails: boolean
): Promise<HttpResult<InstantiateMemberOrgDocsResultDto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `admin/documents/thirdparty/template`,
    {
      template_document: template,
      card_placeholders_list: placeholderInfo,
      source_document_id: sourceDocumentId,
      access_data_ids: accessStatsIds,
      access_mikro_ids: accessMicroIds,
      send_email_to_recipients: sendEmails,
    },
    "POST",
    InstantiateMemberOrgDocsResultDtoRT
  );
}

export function sendThemeToOrg(
  theme: CustomThemeSpec,
  orgId: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/themes`,
    { theme },
    "POST"
  );
}

export function sendThemeToUser(
  theme: CustomThemeSpec,
  orgId: string,
  userId: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/organizations/${orgId}/users/${userId}/themes`,
    { theme },
    "POST"
  );
}
