import { config } from "../../../../config";
import {
  AutoImportGroupListDtoRT,
  AutoImportLogListDtoRT,
  AutoImportScheduleListDtoRT,
  AutoImportScriptDocsDtoRT,
  AutoImportSpecDto,
  AutoImportSpecsListDtoRT,
  ImportOverviewListDtoRT,
  SingleAutoImportSpecDto,
} from "../../../infra/api_responses/admin/import_management";
import { HttpResult } from "../../../infra/HttpResult";
import { authedRequest, decodedAuthedRequest } from "../shared";

export function listAutoImportSpecs(): Promise<
  HttpResult<AutoImportSpecDto[] | null>
> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/autoimport/specs",
    undefined,
    "GET",
    AutoImportSpecsListDtoRT
  );
}

export function getAutoImportSpec(
  id: number
): Promise<HttpResult<SingleAutoImportSpecDto>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/autoimport/specs/${id}`,
    undefined,
    "GET"
  );
}

export function getAutoImportLogs(specId: number) {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `admin/autoimport/specs/${specId}/logs`,
    undefined,
    "GET",
    AutoImportLogListDtoRT
  );
}

export function runAutoImportScript(specId: number) {
  return authedRequest(
    config.apis.statsV2,
    `admin/autoimport/specs/${specId}/run`,
    undefined,
    "POST"
  );
}

export function deleteAutoImportSpec(id: number) {
  return authedRequest(
    config.apis.statsV2,
    `admin/autoimport/specs/${id}`,
    undefined,
    "DELETE"
  );
}

export function createAutoImportSpec(
  dataId: number | undefined,
  groupId: number,
  scheduleId: number,
  type: "statsimport" | "publicfile",
  selectedOrganizationId: string | undefined,
  name: string | undefined,
  comment: string | undefined,
  priority: number,
  script: string,
  notification_recipients: string[],
  overrideTimes: string[] | undefined,
  overrideDays: number[] | undefined,
  overrideMonths: number[] | undefined
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "admin/autoimport/specs",
    {
      auto_import_group_id: groupId,
      auto_import_schedule_id: scheduleId,
      name: name,
      comment: comment,
      org_id: selectedOrganizationId,
      type: type,
      data_id: dataId,
      priority: priority,
      script: script,
      notification_recipients,
      override_scheduled_months:
        (overrideMonths ?? []).length > 0 ? overrideMonths : undefined,
      override_scheduled_days:
        (overrideDays ?? []).length > 0 ? overrideDays : undefined,
      override_scheduled_times:
        (overrideTimes ?? []).length > 0 ? overrideTimes : undefined,
    },
    "POST"
  );
}

export function listImportOverviews() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/importoverview",
    undefined,
    "GET",
    ImportOverviewListDtoRT
  );
}

export function listAutoImportGroups() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/autoimport/groups",
    undefined,
    "GET",
    AutoImportGroupListDtoRT
  );
}

export function listAutoImportSchedules() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/autoimport/schedules",
    undefined,
    "GET",
    AutoImportScheduleListDtoRT
  );
}
export type PatchableAutoImportSpecField =
  | "auto_import_schedule_id"
  | "auto_import_spec_group_id"
  | "override_scheduled_time"
  | "override_scheduled_times"
  | "override_scheduled_days"
  | "override_scheduled_months"
  | "notification_recipients"
  | "data_id"
  | "priority"
  | "disabled"
  | "comment"
  | "name"
  | "org_id"
  | "script";
export function patchAutoImportSpec(
  id: number,
  field: PatchableAutoImportSpecField,
  value: any
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/autoimport/specs/${id}`,
    value === null ? { ["reset_" + field]: true } : { [field]: value },
    "PATCH"
  );
}

export function getImportScriptDocs() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/autoimport/docstrings",
    undefined,
    "GET",
    AutoImportScriptDocsDtoRT
  );
}
